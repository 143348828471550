:root {
  /* --primary-color: #0f0f0f; */
  --primary-color: #131313;
  --secondary-color: #ffffff;
  --link: #61dafb;
}

.App {
  text-align: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: var(--link);
}

header.app-bar-custom-styles {
  /*box-shadow: 0px 2px 4px -1px rgba(97, 218, 251,0.2),0px 4px 5px 0px rgba(97, 218, 251,0.14),0px 1px 10px 0px rgba(97, 218, 251,0.12);*/
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

div.drag-and-drop-custom-styles {
  background-color: transparent;
  border: 3px dashed #282c34;
  padding: 10px;
  margin-top: 20px;
  min-height: 375px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drag-and-drop-custom-styles p {
  color: #282c34;
  font-weight: 300;
}

.form-custom-styles > div > p {
  color: #61dafb;
  font-weight: 300;
  text-align: left;
}

.powered-by-custom-styles {
  font-weight: 300;
  color: white;
  margin-left: 0px;
  margin-top: 17px;
  font-size: 12px;
}

.error-custom-styles {
  color: red;
  font-weight: 300;
  text-align: left;
  font-size: 12px;
  margin-top: 10px;
}

.processing-custom-styles {
  color: rgb(97, 218, 251);
  font-weight: 300;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.result-table-custom-styles {
  max-height: 500px;
  overflow: auto;
}

/* Track */

/* Handle */

.file-list-custom-styles > div {
  padding-left: 6px;
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-bottom: 5px;
  border: 1px solid #282c34;
}

.file-list-custom-styles > div > div > span {
  font-size: 14px;
  font-weight: bold;
}

.file-list-custom-styles > div > div > p {
  font-size: 12px;
  font-weight: 300;
}

.truncated-text {
  white-space: nowrap;
  /* Prevent wrapping */
  overflow: hidden;
  /* Hide overflowing content */
  text-overflow: ellipsis;
  /* Show ellipsis for overflow */
  max-width: your-desired-width;
  /* Set your width limit */
}

.catalog-fields-table-custom-styles input {
  font-size: 13px;
}

.catalog-fields-table-custom-styles {
  max-height: 1000px;
  overflow-y: auto;
  border: 1px solid #dddddd;
}

.result-table-custom-styles {
  margin-top: 20px;
}

.image-custom-styles {
  border: 1px solid #282c34;
}

.button-start-custom-styles {
  padding: 3px !important;
  margin-left: 10px !important;
  color: #61dafb !important;
  border: 1px solid #61dafb !important;
  text-transform: none !important;
  border-radius: 50px !important;
}

.button-start-custom-styles > span {
  font-weight: 300;
  margin-left: 6px;
  margin-right: 6px;
}

.tabs-custom-styles {
  margin-top: 20px;
}

.tabs-custom-styles button {
  padding: 3px;
  width: 33%;
  text-align: center !important;
  text-transform: none;
  color: white;
  font-weight: 300;
}

.tabs-custom-styles button > span {
  width: 100%;
  text-align: center !important;
}

.tabs-custom-styles button.Mui-selected {
  color: #61dafb !important;
}

.tabs-custom-styles button.Mui-selected {
  border-bottom-color: #61dafb !important;
}

.tabs-custom-styles .MuiTabs-indicator {
  background-color: #61dafb !important;
}

.tabs-custom-style svg {
  float: left !important;
  margin-top: -10px;
}

.tabs-title-custom-styles svg {
  float: left !important;
  margin-top: -10px;
}

.has-prompt-custom-styles span {
  font-size: 11px;
}

.catalog-fields-table-custom-styles .image-custom-styles {
  border-radius: 4px;
}

.catalog-fields-table-custom-styles .image-custom-styles > img {
  width: 50px;
}

.app-bar-buttons-custom-styles {
  text-align: right;
  width: 100%;
}

.app-bar-buttons-custom-styles > button {
  margin-left: 10px;
}

.container-custom-styles {
  margin-top: 64px;
}

.container-custom-styles > h1 {
  font-weight: 300;
  text-align: left;
}

body {
  font-family: "Poppins", sans-serif !important;
}

.my-catalog-container-custom-styles {
  text-align: left;
}

.catalog-container-custom-styles {
  text-align: right;
}
.catalog-container-custom-styles > button > svg,
.image-catalog-container-custom-styles > button > svg,
.attributes-catalog-container-custom-styles > button > svg,
.my-catalog-container-custom-styles > button > svg {
  margin-left: 10px;
  margin-right: 10px;
}
.catalog-container-custom-styles > button,
.image-catalog-container-custom-styles > button,
.attributes-catalog-container-custom-styles > button {
  margin-top: 10px;
  margin-left: 10px;
}

.my-catalog-container-custom-styles > button {
  margin-top: 10px;
  margin-right: 10px;
}

.image-catalog-container-custom-styles > .drag-and-drop-custom-styles,
.attributes-catalog-container-custom-styles > .drag-and-drop-custom-styles,
.my-catalog-container-custom-styles > .drag-and-drop-custom-styles {
  text-align: center;
}

.catalog-container-custom-styles > div,
.image-catalog-container-custom-styles > div,
.attributes-catalog-container-custom-styles > div,
.my-catalog-container-custom-styles > div {
  text-align: left;
  align-items: center;
  margin-bottom: 10px;
}
.catalog-container-custom-styles > svg,
.image-catalog-container-custom-styles > div > svg,
.attributes-catalog-container-custom-styles > div > svg {
  margin-right: 10px;
}

.image-catalog-container-custom-styles,
.attributes-catalog-container-custom-styles {
  text-align: left;
}

.icon-white-background > path:first-child {
  color: white;
  opacity: 1;
}

.file-container {
  display: flex;
  height: 100%;
  /* Set container height to 100% */
}

.custom-card-image-styles {
  position: absolute !important;
  z-index: 1000;
  padding: 0 !important;
}

.description-cell {
  white-space: nowrap; /* Prevent wrapping */
  overflow: hidden; /* Hide overflowing content */
  text-overflow: ellipsis; /* Add ellipsis (...) */
  width: 100px; /* Set the width of the container */
}
.catalog-selected-row > td {
  border: 0.75px groove rgb(14, 57, 100) !important;
  border-right: 0px !important;
  border-left: 0px !important;
}
.catalog-selected-row > td:first-child {
  border-left: 0.75px groove rgb(14, 57, 100) !important;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.catalog-selected-row > td:last-child {
  border-right: 0.75px groove rgb(14, 57, 100) !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.check-circle-custom-styles {
  color: green;
}
.check-circle-custom-styles > path:first-child {
  color: white;
}

.data-grid-full-width {
  width: 100%; /* Set the desired width */
}

.custom-select > div {
  padding: 5px;
  font-size: 13px;
}

.preview-data-grid > div {
  font-size: 11px;
}
.MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within {
  outline: 0px !important;
}

.image-stack {
  display: flex; /* Allow horizontal stacking */
  overflow: hidden; /* Hide overflowing parts of cards */
  margin-top: -35px;
  padding-top: 20px;
}

.image-stack__item {
  position: relative; /* Needed for transform */
  width: 150px; /* Adjust card width as needed */
  height: 200px; /* Adjust card height as needed */
  margin-bottom: 10px; /* Optional spacing between cards */
  transition: transform 0.2s ease-in-out; /* Add subtle transition on hover */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Add subtle shadow for depth */
  border-radius: 4px; /* Add rounded corners */
  border: 3px solid #dddddd;
}

.image-stack__item.active {
  /* Style for the top (active) card */
  transform: translateY(0); /* Reset any potential offset */
  z-index: 1; /* Ensure top card overlaps slightly */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3); /* Increase shadow for emphasis */
  margin-top: -10px;
}
.image-name-list {
  display: block;
  height: 20px;
  font-size: 13px;
  line-height: unset;
}
[data-field="images_arr"] {
  line-height: unset !important;
}
.hr-name-list {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(224, 224, 224);
  margin: 0;
  padding: 0;
}

.new-product-card {
  width: 100%;
  margin-block: 5px;
  transform: "translateZ(0)";
  overflow-y: scroll;
  height:30vh;
  .MuiImageListItem-img {
    height: -webkit-fill-available !important;
  }
}

.preview-generate-image .css-dasnyc-MuiImageListItemBar-title {
  font-size: 10px;
}
.incorrect-row {
  background-color: rgba(255, 0, 0, 0.1);
}
.image-list-table{
  height: 100%;
  overflow-y: scroll;
}
.table-custom-styling [role="rowgroup"] .MuiDataGrid-row:hover{
  cursor: pointer;
}